.NovostiMain {
    padding: 100px 20px;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}

.NewsBox {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.news {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border: 2px solid #abba27;
    padding: 10px;
}

.news a button {
    padding: 10px 20px;
    border: 1px solid #abba27;
    outline: none;
    background: none;
    color: #abba27;
    font-size: 18px;
    font-weight: 600;
    font-family: sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s ease-in-out;
}


.news a button:hover {
    background: #abba27;
    color: #fff;
}

.news h1 {
    color: #abba27;
    font-family: sans-serif;
    font-size: 25px;
    text-transform: uppercase;
}

.news span {
    color: grey;
    font-size: 15px;
    font-family: sans-serif;
}

.news p {
    color: white;
    font-family: sans-serif;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    .NewsBox {
        width: 100%;
    }
    .news {
        height: auto;
    }
}