@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.Swiper {
  position: relative;
  display: block;
  z-index: 0;
}

.Slider__img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.Slider__text {
  background-color: #00000057;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 200px;
  padding-left: 20px;
  gap: 30px;
  position: absolute;
  top: 0;
  left: 0;
  font-family: 'Inter', sans-serif;
}

.Slider__text p {
  color: #abba27;
  font-size: 16px;
  font-weight: 700;
}

.Slider__text h1 {
  color: white;
  font-size: 4em;
}