@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.About__us {
    margin-top: 100px;
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px;
    gap: 50px;
}
.About__img {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.About__img img {
    width: 40vw;
}

.About__text {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #abba27;
}
.About__text h1 {
    font-size: 50px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
}

.About__text p {
    width: 80%;
    height: 182px;
    text-align: justify;
}

.About__us span {
    width: 2px;
    height: 100%;
    background-color: #abba27;
}

@media (max-width:768px) {
    .About__img img {
        width: 350px;
    }
    .About__img {
        margin-top: 50px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .About__us span {
        width: 100%;
        height: 3px;
        background-color: #abba27;
    }
} 