.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 100px;
    padding: 0px 20px;
}

.formHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.formHeader h1 {
    color: #ABBA27;
    text-align: center;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: capitalize;
}

.formHeader div {
    width: 25%;
    height: 3px;
    background-color: #ADDA27;
}

.formHeader a {
    text-decoration: none;
}

.formHeader button {
    width: 221px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ADDA27;
    background-color: transparent;
    color: #ADDA27;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: uppercase;
    cursor: pointer;
}

.formHeader button:hover {
    background-color: #ABBA27;
    color: white;
    transition: 0.3s;
}

form {
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 66px;
}

form .text {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 50px;
}

form .text textarea {
    width: 100%;
    height: 240px;
    border: 1px solid #ADDA27;
    outline: none;
    border-radius: 20px;
    background-color: transparent;
    padding: 40px;
    color: rgb(255, 255, 255);
    text-align: start;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.02px;
    text-transform: uppercase;
    resize: none;
}

form .text textarea::placeholder {
    color: rgba(171, 186, 39, 0.28);
    text-align: start;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.02px;
    text-transform: uppercase;
}

form .text input {
    width: calc(50% - 25px);
    height: 58px;
    background-color: transparent;
    box-shadow: none;
    outline: none;
    border-radius: 20px;
    border: 1px solid #ABBA27;
    background: #2D2D2D;
    color: white;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.02px;
    text-transform: uppercase;
}

form .text input::placeholder {
    color: rgba(171, 186, 39, 0.28);
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.02px;
    text-transform: uppercase;
}

form button {
    display: flex;
    width: 219px;
    height: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #ADDA27;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid #ABBA27;
    cursor: pointer;
}

form button:hover {
    background-color: #ABBA27;
    color: white;
    transition: 0.3s;
}

@media screen and (max-width: 768px) {
    .formHeader div {
        display: none;
    }
    .formHeader h1 {
        font-size: 36px;
    }

    .formHeader button {
        width: 180px;
        height: 35px;
        font-size: 16px;
    }
    form .text textarea {
        height: 120px;
        padding: 5px;
        font-size: 16px;
        padding-left: 10px;
    }
    .form .text input {
        width: 100%;
    }
}