#Sob {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    margin-top: 100px;

}

.Sob__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    gap: 50px;
}

.Sob__nav h1 {
    color: #ABBA27;
    text-align: center;
    font-family: Inter;
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: capitalize;
}

.Sob__nav span {
    width: 400px;
    height: 2px;
    background-color: #abba27;
}

.Sob__nav a {
    text-decoration: none;
    width: 181px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ADDA27;
    background-color: transparent;
    color: #ADDA27;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

.Sob__nav a:hover {
    text-decoration: none;
    width: 181px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ADDA27;
    background-color: transparent;
    color: #fff;
    text-align: center;
    background-color: #ABBA27;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 90px;
    text-transform: uppercase;
}

.Sob__container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.Sob__box__contant {
    margin-top: 100px;
    padding: 0 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.Sob__box__img {
    width: 44vw;
    height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    border-radius: 10px;
}

.Sob__box__img img {
    width: 44vw;
    height: 45vh;
    object-fit: cover;
    border-radius: 10px;
}

.Sob__news1 {
    margin-top: -80px;
    height: 80px;
    width: 70%;
    background-color: #2d2d2d;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    padding: 7px;
    border-top: 1px solid #ABBA27;
    border-left: 1px solid #abba27;
    border-right: 1px solid #abba27;
    /* border: 1px solid #ABBA27; */
}

.Sob__newss {
    margin-top: -80px;
    height: 80px;
    width: 70%;
    background-color: #2d2d2d;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.Sob__newss p {
    width: 350px;
    text-align: left;
    color: white;
}

.Sob__news1 p {
    width: 350px;
    text-align: left;
    color: white;
}


.Sob__newss h4,
.Sob__news1 h4 {
    width: 350px;
    text-align: left;
    color: #abba27;
    font-size: 18px;
}


@media screen and (max-width: 768px) {
    .Sob__nav a {
        width: 180px;
        height: 35px;
    }
}