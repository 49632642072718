.producsiya {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    gap: 100px;
}

.producsiyaSwip {
    width: 55vw;
    height: 50vh;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    z-index: 0;
}

.mySwiper1 {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

#slider {
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mySwiper1 .texnikaImg {
    width: 30%;
    height: 100%;
    object-fit: unset;
}

.mySwiper1 .box {
    width: 70%;
    height: 100%;
    background: rgba(45, 45, 45, 0.733);
    padding-left: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 70px;
}

.producsiyaHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;
}

.producsiyaHeader h1 {
    color: #ABBA27;
    font-size: 50px;
}

.producsiyaHeader div {
    width: 25%;
    height: 3px;
    background-color: #ABBA27;
}


.producsiyaHeader button {
    width: 221px;
    height: 60px;
    background-color: transparent;
    color: #ABBA27;
    border: 1px solid #ABBA27;
    font-size: 18px;
}

.producsiyaHeader button:hover {
    background-color: #ABBA27;
    color: white;
    transition: 0.3s;
    cursor: pointer;
}

.mySwiper1 .box h1 {
    color: white;
    font-size: 42px;
}

.mySwiper1 .box div {
    width: 50%;
    height: 5px;
    background-color: #ABBA27;
}

.mySwiper1 .box button {
    padding: 16px 65px;
    background-color: transparent;
    color: #ABBA27;
    font-weight: 600;
    border: 1px solid #ABBA27;
    font-size: 26px;
}

.mySwiper1 .box button:hover {
    background-color: #ABBA27;
    color: white;
    transition: 0.3s;
    cursor: pointer;
}


@media (max-width:768px) {
    .mySwiper1 .box h1 {
        width:auto ;
        font-size: 25px;
      }
      .mySwiper1 .box {
        width: 100%;
        height: auto;
        background: rgba(45, 45, 45, 0.733);
        padding: 20px;
        display: flex;
        align-content: space-between;
        flex-direction: column;
        gap: 20px;
    }

    .mySwiper1 .box button {
        padding: 10px 40px;
        background-color: transparent;
        color: #ABBA27;
        font-weight: 600;
        border: 1px solid #ABBA27;
        font-size: 20px;
    }
    .mySwiper1 .texnikaImg {
        width: 100%;
        height: 50%;
        object-fit: contain;
        background: #2d2d2dba;;
    }
    .producsiya {
        gap: 0;
    }
    .producsiyaHeader h1 {
        font-size: 32px;
    }
    .producsiyaHeader div {
        display: none;
    }

    .producsiyaHeader button {
        width: 180px;
        height: 35px;
        font-size: 16px;
    }
}