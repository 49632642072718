

.accordion {
    display: flex;
    width: 100%;
}

.item {
    width: 100%;
    margin-bottom: 5px;
}

.title {
    color: #fff;
    font-size: 10px;
    width: 100%;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.title h2 {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    transition: all 0.3s ease;
}

.title h2:focus,
.title h2:hover {
    color: #ABBA27;
}



.akcioner {
    padding: 0 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 80px;
    left: 0;
    max-height: 0;
    overflow: hidden;
    transition: all 0.8s cubic-bezier(0,2,0,2);
}

.content__text1 {
    width: 100%;
    display: flex;
    height: auto;
    flex-wrap: wrap;
    justify-content:space-between ;
    gap: 20px;
}

.akcioner.show {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 100%;
    background-color: #2d2d2d;
    position: absolute;
    top: 80px;
    display: flex;
    justify-content: flex-start;
    height: auto;
    max-height: 9999px;
    transition: all 0.5s cubic-bezier(1,0,1,0);
    border: 2px solid #ABBA27;
}

.box__b {
    padding: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.655);
    gap: 15px;
}

.box__b a{
    color: #ABBA27;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 400;
    width: 250px;
}


.box__b h1 {
    width: 250px;
    font-size: 20px;
    color: white;
}

.box__a2 a{
    color: #ABBA27;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.box__a3 {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.box__a3 a{
    color: #ABBA27;
    text-decoration: none;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.news__box1 {
    padding: 50px 0;
    width: 100%;
}

.news__box__link1 {
    display: flex;
    justify-content: center;
    gap: 100px;
}

.news__box__link1  a{
    color: #ABBA27;
    text-decoration: none;
    font-weight: 600;
}