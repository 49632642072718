.footer {
    display: flex;
    gap: 70px;
    padding: 0px 80px;
    padding-bottom: 90px;
    padding-top: 32px;
    background-color: #272727;
}

.footerLeft {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-right: 50px;
}

.footerLeft img {
    width: 100px;
}

.footerLeft button {
    display: flex;
    width: 200px;
    height: 45px;
    justify-content: center;
    align-items: center;
    background: #ABBA27;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.563px;
    cursor: pointer;
    border: none;
}

.footerRight {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 59px;
}

.footerRight_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.footerRight_top ul {
    text-decoration: none;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.footerRight_top ul li {
    color: #FFF;
    font-size: 16px;
    text-decoration: none;
}

.footerRight_top ul li:hover {
    color: #ABBA27;
}

.footerRight_top ul a {
    text-decoration: none;
}

.footerRight_bottom {
    display: flex;
    justify-content: space-between;
}

.footerRight_bottom span {
    color: #FFF;
    font-family: Inter;
    font-size: 14.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.563px;
}

.footerRight_bottom div {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footerRight_bottom div h5 {
    color: #FFF;
    font-family: Inter;
    font-size: 15.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.563px;
}

.footerRight_bottom div span {
    color: #FFF;
    font-family: Inter;
    font-size: 14.5px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.563px;
}


@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        padding: 20px;
    }

    .footerRight {
        flex-direction: column;
    }
    .footerRight_bottom {
        justify-content: start;
        gap: 25px;
    }

    .footerRight_top {
        flex-wrap: wrap;
        gap: 25px;
    }
}