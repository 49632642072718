.KaryeraContent {
    display: flex;
    justify-content: space-between;
    padding: 50px 20px;
}

.leftSide {
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.KaryeraBox {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 2px solid #abba27;
    padding: 30px;
}

.KaryeraBox p {
    color: rgb(196, 191, 191);
}

.KaryeraBox h1 {
    color: #abba27;
}

.KaryeraBox__button {
    margin-top: 30px;
    width: 100%;
}

.KaryeraBox__button a{
    text-decoration: none;
}

.KaryeraBox__button .a-button{
    padding: 10px 30px;
    border: 1px solid #abba27;
    color: #abba27;
    text-decoration: none;
    text-transform: uppercase;
    font-size:12px;
    font-weight: 600;
    transition: all .3s ease;
}

.KaryeraBox__button .a-button:hover {
    color: white;
    background-color: #abba27;
}

.aside {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 20rem;
    border: 2px solid #abba27;
}

.aside__box {
    display: flex;
    flex-direction: column;
    gap: 40px;
    font-family: sans-serif;
}

.aside__box h1 {
    width: 100%;
    color: white;
    text-transform: uppercase;
}

.aside__box li,
.aside__box li a {
    width: 100%;
    font-size: 20px;
    text-align: start;
    text-decoration: none;
    list-style: none;
    color: rgb(175, 172, 172);
}


.aside__box li a:hover,
.aside__box li a:focus {
    color: #abba27;
}


@media screen and (max-width: 768px) {
    .KaryeraContent {
        flex-direction: column-reverse;
        position: relative;
        gap: 20px;
    }
    .aside {
        width: 100%;
        max-height: 50px;
        justify-content: flex-start;
    }
    .aside__box {
        text-align: center;
        justify-content: flex-start;
        flex-direction: row;
        padding-left: 10px;
    }
    .aside__box h1 {
        display: none;
    }
    .aside__box li, .aside__box li a {
        width: auto;
    }
    .leftSide {
        width: 100%;
    }
}