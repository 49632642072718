.BatavsilMain {
    width: 100%;
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    gap: 200px;
}

.BatavsilMain__leftSide {
    width: 60%;
    height: auto;
    max-height: 9999px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 50px;
    border: 2px solid #abba27;
}

.BatavsilMain__header {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.BatavsilMain__header p {
    color: rgb(173, 172, 172);
    font-size: 14px;
}

.BatavsilMain__header h1 {
    color: #abba27;
}

.BatavsilMain__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    text-align: justify;
    color: rgb(233, 229, 229);
    font-size: 20px;
}

.Ariza a{
    text-decoration: none;
}

.Ariza {
    width: 100%;
    margin: 20px 0;
}

.ArizaButton {
    padding: 15px 25px;
    color: #abba27;
    border: 1px solid #abba27;
    text-decoration: none;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 600;
    transition: all .3s ease;
}

.ArizaButton:hover {
    color: #fff;
    background-color: #abba27;
}