.texnikaMian {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
}

.texnikaTitle { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 400px;
    margin: 50px 0;
    color: #ABBA27;
    font-size: 46px;
    font-weight: 600;
}

.texnikaTitle span {
    width: 100%;
    height: 3px;
    background-color: #ABBA27;
}

.texniks {
    display: flex;
    flex-direction: column;
    gap: 56px;
}

.texniks .texnikaCard {
    border: 1px solid #ABBA27;
    display: flex;
    gap: 20px;
}

.texniks .texnikaCard img {
    width: 300px;
    height: 100%;
}

.texniks .texnikaCard .texnikaRight {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 36px;
}

.texniks .texnikaCard .texnikaRight span {
    font-size: 44px;
    color: white;
    font-weight: 600;
}

.texniks .texnikaCard .texnikaRight div {
    width: 25%;
    height: 5px;
    background-color: #ABBA27;
}

.texniks .texnikaCard .texnikaRight button {
    width: 220px;
    height: 50px;
    cursor: pointer;
    border: none;
    background-color: #ABBA27;
    color: white;
    font-size: 26px;
    position: absolute;
    right: 60px;
    bottom: 30px;
}



@media screen and (max-width: 768px) {
    .texnikaCard {
        flex-wrap: wrap;
    } 
    .texnikaLeft {
        width: 100%;
    }
    .texniks .texnikaCard img {
        width: 100%;
    }

    .texnikaRight {
        flex-direction: column;
        align-items: start;
        gap: 20px;
    }

    .texnikaRight div {
        display: none;
    }

    .texniks .texnikaCard .texnikaRight button {
        width: 180px;
        height: 35px;
        font-size: 16px;
        position: relative;
        right: 0;
        bottom: 0;
    }

    .texniks .texnikaCard .texnikaRight span {
        width: auto;
        flex-wrap: wrap;
        font-size: 32px;
    }
}