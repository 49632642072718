.texInfo {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px; 
    justify-content: center;
    align-items: center;
}

.texnikaINfo {
    width: 80%;
    display: flex;
    gap: 25px;
    border-bottom: 1px solid #ABBA27;
    padding-bottom: 10px;
}


.texnikaINfoImg {
    border-radius: 10px;
}

.texnikaINfoImg img {
    width: 300px;
}


.texnikDescription {
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.texnikDescription h4 {
    font-size: 28px;
    color: #ABBA27;
}

.texnikDescription p {
    color: #fff;
    font-size: 19px;
}

.texnikDescription button {
    width: 180px;
    padding: 5px 0px;
    cursor: pointer;
    background-color: #ABBA27;
    color: white;
    border: none;
    border-radius: 3px;
    font-size: 18px;
}

.texnikDescription button:hover {
    border: 1px solid #ABBA27;
    background-color: transparent;
    color: #ABBA27;
    transition: 0.3s;
}
.texnikDescription button:active {
    transform: scale(0.96);
}   


.texTable {
    width: 80%;
    display: flex;
}

.texTable table {}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: 1px solid #ABBA27;
    text-align: left;
    padding: 8px;
    color: white;
}

tr:nth-child(even) {
    background-color: #dddddd1f;
}

@media screen and (max-width: 768px) {
    .texInfo {
        align-items: flex-start;
    }
    .texnikaINfo {
        flex-direction: column;
    }
    .texnikDescription {
        width: 100%;
    }
}