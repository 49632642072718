.TexPgageFlex {
    display: flex;
    flex-direction: column;
    gap: 300px;
    margin-bottom: 120px;
}


.TexPage {
    margin: 150px 0 0 0 ;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    display: flex;
    gap: 40px;
}

.TexPage__Left {
    width: 30%;
    height: 300px;
    background-color: grey;
}

.TexPage__Right {
    padding: 50px 60px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    border: 1px solid #abba27;
}

.TexPage__text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 25px;
}

.TexPage__text h1 {
    color: #abba27;
    font-family: sans-serif;
}

.TexPage__text p {
    color: #fff;
    font-family: sans-serif;
}

.TexPage__button {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 50px;
}

.TexPage__button button {
    font-size: 17px;
    font-weight: 600;
    color: #abba27;
    width: 220px;
    height: 50px;
    outline: none;
    background-color: inherit;
    border: 1px solid #abba27;
    transition: all .3s ease;
}

.TexPage__button button:hover {
    font-size: 17px;
    color: #fff;
    width: 220px;
    height: 50px;
    outline: none;
    background-color: #abba27;
    border: 1px solid #abba27;
}


.TexPage__Left img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .TexPage {
        flex-direction: column;
        gap: 0px;
    }
    .TexPgageFlex {
        gap: 100px;
    }
    .TexPage__Left {
        width: 100%;
    }
    .TexPage__Right {
        width: 100%;
        gap: 15px;
        padding: 15px;
    }
    .TexPage__button {
        justify-content: flex-start;
    }

    .TexPage__text h1 {
        font-size: 34px;
    }
    .TexPage__text p {
        font-size: 22px;
    }
    .TexPage__button button {
        font-size: 14px;
    }
}