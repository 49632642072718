$font-family: "Open Sans", sans-serif;
$font-size: 1em;

$color-body: #e2e2e2;
$color-text: #fff;
$color-menu: #2d2d2d;
$color-fixed: #abba27;

$variable-menu-animation-time: 1s;
$variable-burger-animation-time: 0.35s;

$variable-menu-animation-function: cubic-bezier(0.25, 0.1, 0.25, 1);
$variable-burger-animation-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

*,
*:before,
*:after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#menu--toggle[type="checkbox"]:not(:checked),
#menu--toggle[type="checkbox"]:checked {
  display: none;
}

.menu--toggle__trigger,
.menu--toggle__burger,
.menu--toggle__burger:before,
.menu--toggle__burger:after {
  position: absolute;
  top: 1em;
  left: 1.1em;
  width: 2em;
  height: 5px;
  background-color: $color-fixed;
  border-radius: 2px;
  cursor: pointer;
  z-index: 100;
  transition: $variable-burger-animation-time $variable-menu-animation-function;
}

#menu {
  width: 100vw;
}

.menu--toggle__trigger {
  height: 2em;
  background: none;
}

.menu--toggle__burger {
  &:before {
    content: "";
    top: 10px;
    left: 0;
  }
  &:after {
    content: "";
    top: 20px;
    left: 0;
  }
}

.menu {
  position: fixed;
  width: 100vw;
}

.divTuggleOb {
  width: 100vh;
  position: relative;
  top: 0;
  z-index: 100;
}

.menu__body {
  display: flex;
  align-items: flex-start;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 60px;
  margin: 0;
  padding: 0 20px;
  background-color: $color-menu;
  box-shadow: 3px 3px 7px rgba($color-text, 0.2);
  z-index: 10;
  animation: checkboxUncheckedAnimation $variable-burger-animation-time both;
}

.menu__body-element,
.menu__body-link {
  display: none;
  margin: 4.4em 0 -3.3em -0.1em;
  font-weight: normal;
  color: $color-text;
  text-decoration: none;
  text-transform: none;
  list-style: none;
  outline: 0;
}

.menu__body-element {
}

.menu__body-link:hover {
  display: block;
  width: 0;
  color: $color-text;
  background-color: $color-body;
  animation: changeWidthOfElementAnimation $variable-burger-animation-time both;
}

#menu--toggle:checked + .menu--toggle__trigger {
  & + .menu--toggle__burger {
    top: 35px;
    transform: rotate(405deg);
    transition: transform $variable-burger-animation-time
      $variable-burger-animation-function;

    &:before {
      top: 0;
      transform: rotate(-90deg);
      transition: transform $variable-burger-animation-time
        $variable-burger-animation-function;
    }
    &:after {
      top: 0;
      transform: rotate(90deg);
      transition: transform $variable-burger-animation-time
        $variable-burger-animation-function;
    }
  }

  & + .menu--toggle__burger + .menu__body {
    animation: checkboxCheckedAnimation $variable-menu-animation-time
      $variable-menu-animation-function both;
  }

  & ~ .menu__body .menu__body-element,
  & ~ .menu__body .menu__body-link {
    display: block;
  }
}

.promotion {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

@keyframes checkboxCheckedAnimation {
  50% {
    width: 100vw;
    height: 100vh;
  }
  100% {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}

@keyframes checkboxUncheckedAnimation {
  0% {
    width: 100vw;
    height: 100vh;
  }
}

@keyframes changeWidthOfElementAnimation {
  50% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.sub-menu {
  display: none;
}
.main-item:focus ~ .sub-menu,
.main-item:active ~ .sub-menu,
.sub-menu:hover {
  display: block;
}

#wrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 20px 0;
}

#wrapper .button {
  font-family: sans-serif;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  &:active {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25) inset;
  }
}

.podcat {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.podcat a {
  text-decoration: none;
  color: #fff;
}

.podcat a h4 {
  font-size: 20px;
  font-family: sans-serif;
  font-weight: 400;
}

.content {
  display: flex;
  justify-content: flex-start;
}

.content h1 {
  font-size: 18px;
  color: #abba27;
  display: flex;
  justify-content: flex-start;
}

.overlay {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 150px 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #2d2d2d;
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 1;
  overflow: scroll;
  .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default;
  }
  &:target {
    visibility: visible;
    opacity: 1;
  }
}

.close {
  position: absolute;
  width: 45px;
  height: 45px;
  top: 90px;
  left: 20px;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 35px;
  font-weight: bold;
  text-decoration: none;
  color: #abba27;
  &:hover {
    opacity: 1;
  }
}

.none {
  display: none;
}

.MapBox a{
  text-decoration: none;
  color: #fff;
}

.popup {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.popup h4 {
  display: block;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-weight: bold;

}


.logoUkm {
  position: absolute;
  height: 60px;
  padding-bottom: 10px;
  padding-top: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100vw;
  z-index: 90;
  padding-right: 1.1em;
  background-color: #2d2d2d;
}

.languageResponsive {
  display: flex;
  align-items: center;
  gap: 3px;
  color: #fff;
  font-size: 17px;
  font-family: sans-serif;
}

.languageResponsive button {
  outline: none;
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
}

.languageResponsive button:hover,
.languageResponsive button:focus {
  outline: none;
  background: none;
  border: none;
  color: #abba27;
  font-size: 17px;
}


.GlavBtw {
  width: 56%;
  display: flex;
  justify-content: space-between;
}

.GlavBtw img {
  width: 80px;
  height: 35px;
}

.article {
  position: relative;
  z-index: 0;
}