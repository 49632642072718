.contact {
    width: 100%;
    padding: 70px 20px;
}
.locationInfo {
    display: flex;
    border: 1px solid #ABBA27;
    padding-top: 40px;
    padding-bottom: 130px;
}

.locationInfoLeft,
.locationInfoRight {
    width: 50%;
}

.locationInfoLeft {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 31px;
    padding-left: 90px;
}

.locationInfoLeft span {
    color: #ABBA27;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    text-transform: uppercase;
}

.locationInfoLeft h1 {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 28.125px;
}

.locationInfoLeft button {
    width: 176px;
    height: 45px;
    flex-shrink: 0;
    background-color: #ABBA27;
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.125px;
    text-transform: uppercase;
    cursor: pointer;
    border: none;
    margin-top: 30px;
}

.locationInfoRight {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.locationInfoRight a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.locationInfoRight a span {
    color: #FFF;
    font-family: Inter;
    font-size: 18.375px;
    font-style: normal;
    font-weight: 400;
    line-height: 31.5px;
}

.ymaps-layers-pane {
	filter: grayscale(1);
	-ms-filter: grayscale(1);
	-webkit-filter:  invert(90%) grayscale(100%);
	-moz-filter: grayscale(1);
	-o-filter: grayscale(1);
}

@media screen and (max-width: 768px) {
    .locationInfo {
        flex-direction: column;
        gap: 25px;
    }
    .locationInfoLeft {
        padding-left: 15px;
    }

    .locationInfoLeft span {
        font-size: 18px;
    }

    .locationInfoLeft h1 {
        font-size: 36px;
    }

    .locationInfoRight {
        padding-left: 30px;
        gap: 25px;
        flex-wrap: wrap;
    }

    .locationInfoRight a {
        gap: 10px;
    }
}