.NovostiPage {
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.NovPageUp {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;
    gap: 30px;
}

.NavPageImage {
    width: 50vw;
    height: 21vw;
    border: 2px solid #abba27;
}

.NavPageImage img {
    width: 100%;
    height: 100%;
}

.NavPageText {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 50vw;
    height: 21vw;
    max-width: auto;
    max-height: auto;
    padding: 10px;
    border: 2px solid #abba27;
}


.NavPageText p {
    color: white;
    font-family: sans-serif;
    font-size: 1vw;
    text-align: justify;
}
.NovPageBtn {
    margin-top: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NovPageBtn button {
    outline: none;
    border: 1px solid #abba27;
    background:none;
    padding: 10px 60px;
    color: #abba27;
    font-size: 14px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s all;
}

.NovPageBtn button:hover {
    background-color: #abba27;
    color: white;
}

@media (max-width:768px) {
    .NovPageUp {
        display: flex;
        flex-direction: column;
    }
    .NovPageUp {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 20px;
        gap: 30px;
    }

    .NavPageImage {
        width: 90vw;
        height: 45vw;
        border: 2px solid #abba27;
    }

    .NavPageText p {
        color: white;
        font-family: sans-serif;
        font-size: 3vw;
        text-align: justify;
    }



    .NavPageText {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 90vw;
        height: 50vw;
        max-width: auto;
        max-height: auto;
        padding: 10px;
        border: 2px solid #abba27;
    }
}