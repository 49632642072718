.RukContant {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0 20px;
}

.RecContent__title {
  display: flex;
  flex-direction: column;
  width: 800px;
  font-size: 30px;
  color: #abba27;
  font-family: sans-serif;
  margin: 100px 0;
  gap: 20px;
}

.RukContent__box {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.RukContent__Left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 42px 0px rgba(171, 186, 39, 0.2);
  -moz-box-shadow: 0px 0px 42px 0px rgba(171, 186, 39, 0.2);
  box-shadow: 0px 0px 42px 0px rgba(171, 186, 39, 0.2);
}

.RukContent__Left img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.RecContent__title h1 {
  text-transform: uppercase;
  font-family: sans-serif;
}

.RecContent__title h4 {
  color: grey;
}

.RukContent__Right {
  padding-top: 100px;
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 150px;
}

.RukContent__Right p {
  width: 85%;
  color: white;
  font-size: 25px;
  border-left: 3px solid #abba27;
  padding-left: 50px;
}

@media screen and (max-width: 768px) {
  .RukContent__box {
    flex-direction: column;
  }

  .RecContent__title {
    width: 100%;
  }
  .RukContent__Left {
    width: 100%;
  }
  .RecContent__title h1 {
    font-size: 28px;
  }

  .RecContent__title h4 {
    font-size: 20px;
  }

  .RukContent__Right {
    width: 100%;
  }

  .RecContent__Duwn {
    margin-top: 40px;
  }
  .RukContent__Right p {
    width: 100%;
    color: white;
    font-size: 18px;
    border-left: 3px solid #abba27;
    padding-left: 20px;
  }
  .RukContent__Right {
    padding-top: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 80px;
  }
}
