* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


.first__menu__buttons ul {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.first__menu__buttons ul li {
    list-style: none;
}

.first__menu__buttons ul li button {
    text-decoration: none;
    outline: none;
    background: inherit;
    border: none;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: .2s all ease;
    border: none;
}

.first__menu__buttons ul li button:hover {
    text-decoration: none;
    color: #abba27;
}

.first__menu__buttons ul li button:focus {
    text-decoration: none;
    color: #abba27;
}

.language__buttons button {
    outline: inherit;
    border: none;
    background: none;
    color: #fff;
    cursor: pointer;
    transition: .2s all ease;
}

.language__buttons button:focus,
.language__buttons button:hover {
    color: #abba27;
}

.language__buttons {
    display: flex;
    gap: 2px;
}

.language__buttons button {
    font-size: 16px;
}

.language__buttons p {
    color: #fff;
}

.Navbar__up {
    padding: 0px 20px;
    width: 100%;
    height: 30px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Navbar__up li a {
    text-decoration: none;
}

.Navbar__duwn {
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.Navbar__logo {
    width: 80px;
}
.Navbar__logo img {
    width: 100%;
}

.Navbar__logo2 {
    width: 30px;
    display: flex;
    align-items: center;
}

.Navbar__logo2 button {
    outline: inherit;
    border: none;
    background: none;
    cursor: pointer;
}

.Navbar__logo2 img {
    width: 100%;
}

.Second__menu__buttons {
    display: flex;
    align-items: center;
    gap: 20px;
}

.navbar {
    display: flex;
    gap: 50px;
    overflow: hidden;
    font-family: Arial, Helvetica, sans-serif;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fontChaneger {
    display: flex;
    flex-direction: row;
    gap: 4px;
    position: absolute;
    left: 100px;
}

.fontChaneger button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 23px;
    border: 1px solid #abba27;
    color: white;
    background: transparent;
    padding: 3px;
}

.title a {
    text-decoration: none;
    color: #fff;
}

.nav__button__box {
    width: 100%;
}

.fixed {
    background-color: #2d2d2d;
    border-bottom: 2px solid #abba27;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    animation: animfixed .8s; 
}

@keyframes animfixed {
    0% {
        transform: translateY(-100px);
    }100% {
        transform: translateY(0);
    }
}

 