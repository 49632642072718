.ao {
    display: flex;
    flex-direction: column;
}


.aoMain {
    margin: 200px 55px;
    display: flex;
    align-items: flex-start;
}

.aoMain button {
    border: 3px solid #ABBA27;
    background-color: transparent;
    padding: 5px 15px;
    cursor: pointer;
}

.aoMain button div {
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.aoMain button div p {
    text-align: start;
    color: #ABBA27;
    font-size: 22px;
}

.aoMain table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 60%;
}

.aoMain td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  color: #ABBA27;
}

.aoMain td {
    cursor: pointer;
}

.aoMain tr button {
    color: white;
    font-weight: 600;
}

.aoMain tr button:active {
    transform: scale(0.95);
}

@media screen and (max-width: 768px) { 
    .aoMain {
        margin: 50px 20px;
    }
    .aoMain table {
        width: 100%;
    }
}