* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #2d2d2d;
  font-family: sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
}

.App__navbar {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.App__nav__up {
  position: absolute;
  width: 100%;
  z-index: 1;
}

.App__nav__burg {
  display: none;
}

.App__nav__slide {
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .App__nav__burg {
    display: block;
    position: fixed;
    z-index: 2;
  }
  .App__nav__up {
    display: none;
  }
  .App__nav__slide {
    width: 100%;
  }
}

.ghost {
  top: 0;
  position: absolute;
  transition: all 0.5s ease;
}

.swipe__up__button {
  text-decoration: none;
  transition: all 0.5s ease;
}

.swipe__up {
  border-radius: 50%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 10px;
  right: 10px;
  width: 70px;
  height: 70px;
  background-color: #abba27;
  color: white;
  border: 1px solid #abba27;
  color: #abba27;
  overflow: hidden;
  transition: all 0.3s ease;
  z-index: 1;
}

.swipe__up .icon-md {
  padding: 7px 10px;
  color: #fff;
  transition: all 0.3s ease;
}

.swipe__up .icon-md:hover {
  color: #abba27;
}

.swipe__up:hover {
  color: #abba27;
  background-color: #fff;
}

@media (max-width: 768px) {
  .About__us {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-top: 0;
  }

  .About__text {
    width: 100%;
  }

  .About__us p {
    width: 100%;
  }

  .Sob__box__contant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .Sob__box__img {
    width: 100%;
  }

  .Sob__box__img img {
    width: 100%;
    object-fit: cover;
  }

  .Sob__nav span {
    display: none;
  }

  .Sob__nav h1 {
    font-size: 44px;
  }

  .Sob__newss h4,
  .Sob__news1 h4 {
    width: auto;
  }

  #slider {
    display: flex;
    flex-direction: column;
  }
}
