.AbutPage {
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 300px;
}

.AboutPageGlav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.AboutPage__title {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.AboutPage__title h1 {
  color: #abba27;
  font-family: sans-serif;
  font-size: 55px;
}

.AboutPage__title h4 {
  color: grey;
  font-family: sans-serif;
  font-size: 40px;
}

.AboutBox {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 130px;
}

.AboutBox span {
  width: 3px;
  height: 100%;
  background-color: #abba27;
}

.AboutLeftBox p {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
}

.AboutRightBox p {
  color: white;
  font-family: sans-serif;
  font-size: 20px;
}

.Mission {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.MissionTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MissionTitle h1 {
  color: #abba27;
  font-size: 60px;
  font-family: sans-serif;
}

.MissionText p {
  text-align: center;
  color: #fff;
  font-size: 25px;
  width: 800px;
}

.Cennosti {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
  justify-content: center;
}
.CennostiTitle h1 {
  color: #abba27;
  font-size: 50px;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ConnostiGlavBox {
  width: 95vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;
}

.CennostiBox {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  padding: 50px;
  background-color: rgba(128, 128, 128, 0.4);
  width: 700px;
  height: 250px;
}

.CennostiBox h2 {
  width: 500px;
  height: 100px;
  color: #abba27;
  font-size: 27px;
  text-transform: uppercase;
}

.CennostiBox p {
  color: #fff;
  font-size: 25px;
  font-family: sans-serif;
}

.Osnovatel {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 80px;
}

.OsnovatelTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.OsnovatelTitle h1 {
  font-size: 50px;
  font-family: sans-serif;
  color: #abba27;
}

.OsnovatelTitle h4 {
  color: grey;
  font-family: sans-serif;
  font-size: 40px;
}

.OsnovatelBoxGlav {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.OsnovatelLeftBox {
  width: 800px;
  display: flex;
  flex-direction: column;
  gap: 200px;
}

.OsnovatelRightBox {
  width: 700px;
  display: flex;
  justify-content: center;
}
.OsnovatelRightBox img {
  width: 100%;
  object-fit: cover;
}

.OsnovatelBoxOne {
  margin-top: 200px;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 200px;
}

.OsnovatelBoxOne span {
  width: 20px;
  height: 100%;
  background-color: #abba27;
}

.OsnovatelBoxOne p {
  color: #fff;
  font-size: 25px;
}

.OsnovatelBoxSecond p {
  color: #fff;
  font-size: 25px;
}

.OsnovatelBoxSecond {
  display: flex;
  align-items: center;
  gap: 30px;
  height: 200px;
}

.OsnovatelBoxSecond span {
  width: 20px;
  height: 100%;
  background-color: #abba27;
}

@media (max-width: 768px) {
  .About__us {
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 20px;
    margin-top: 0;
  }

  .About__text {
    width: 100%;
  }

  .About__us p {
    width: 100%;
  }

  .Sob__box__contant {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
  }

  .Sob__box__img {
    width: 100%;
  }

  .Sob__box__img img {
    width: 100%;
    object-fit: cover;
  }

  .Sob__nav span {
    display: none;
  }
  .Zavod__title {
    width: 100%;
    display: flex;
    justify-content: center;
}
  .Sob__nav h1 {
    font-size: 36px;
    text-align: center;
  }

  .Sob__newss h4,
  .Sob__news1 h4 {
    width: auto;
  }
}

.ZavodRight {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.zavodGlav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 100px;
  width: 100%;
  height: 1200px;
}

.Zavod__title {
  display: block;
}

.Zavod__title h1 {
  font-size: 60px;
  color: #abba27;
}

.zavod {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.ZavodImage {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.ZavodImage img {
  width: 35rem;
  border: 1px solid #abba27;
  border-radius: 10px;
}

.ZavodLeft {
  width: 90%;
  height: 100%;
}

.ZavodText {
  width: 100%;
  height: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 100px;
  color: #fff;
}

.Text_zav {
  display: flex;
  gap: 20px;
}

.Text_zav p {
  width: 64%;
  display: flex;
  font-size: 25px;
  padding-bottom: 60px;
  border-bottom: 3px solid grey;
}

.Text_zav span {
  background-color: #abba27;
  width: 30px;
  height: 10px;
  border-radius: 10px;
}

@media (max-width: 768px) {
  .OsnovatelBoxGlav {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .OsnovatelRightBox {
    width: 21rem;
    display: flex;
    justify-content: center;
  }
  .OsnovatelRightBox img {
    width: 100%;
    object-fit: cover;
  }
  .OsnovatelTitle h1 {
    font-size: 30px;
    font-family: sans-serif;
    color: #abba27;
  }

  .OsnovatelTitle h4 {
    font-size: 20px;
  }

  .promotion {
    width: 100vw;
    height: 50vh;
    overflow: hidden;
    position: relative;
    z-index: 0;
  }

  .OsnovatelLeftBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;
    height: 100%;
  }

  .OsnovatelBoxOne {
    width: 90vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .OsnovatelBoxOne p {
    width: 100%;
    color: #fff;
    font-size: 20px;
    text-align: justify;
  }

  .OsnovatelBoxSecond {
    width: 90vw;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .OsnovatelBoxSecond p {
    width: 100%;
    height: 100%;
    color: #fff;
    font-size: 20px;
    text-align: justify;
  }

  .OsnovatelBoxOne span {
    width: 100%;
    height: 3px;
  }

  .OsnovatelBoxSecond span {
    width: 100%;
    height: 3px;
  }

  .AboutPage__title h1 {
    color: #abba27;
    font-family: sans-serif;
    font-size: 30px;
  }

  .AboutPage__title h4 {
    color: grey;
    font-family: sans-serif;
    font-size: 20px;
  }

  .AboutBox {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }

  .AboutBox span {
    width: 100%;
    height: 3px;
    background-color: #abba27;
  }

  .zavod {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .Text_zav {
    width: 90vw;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .ZavodImage img {
    width: 100%;
    border: 1px solid #abba27;
    border-radius: 10px;
  }

  .AboutLeftBox p {
    color: white;
    text-align: justify;
    font-family: sans-serif;
    font-size: 20px;
  }

  .AboutRightBox p {
    color: white;
    text-align: justify;
    font-family: sans-serif;
    font-size: 20px;
  }

  .zavodGlav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 100px;
    width: 100%;
    height: 100%;
}

  .ZavodRight {
    width: 90vw;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .Text_zav p {
    text-align: justify;
    width: 90vw;
    display: flex;
    font-size: 25px;
    padding-bottom: 60px;
    border-bottom: 3px solid grey;
  }

  .Mission {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.MissionTitle h1 {
    color: #abba27;
    font-size: 40px;
    font-family: sans-serif;
}

.MissionText p {
    text-align: center;
    color: #fff;
    font-size: 20px;
    width: 100%;
}

  .CennostiBox {
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    padding: 50px;
    background-color: rgba(128, 128, 128, 0.4);
    width: 100%;
    height: 250px;
  }

  .CennostiBox h2 {
    width: 100%;
    height: 100%;
    color: #abba27;
    font-size: 17px;
    text-transform: uppercase;
  }

  .CennostiBox p {
    color: #fff;
    font-size: 20px;
    height: auto;
    font-family: sans-serif;
}

.ZavodImage {
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.OsnovatelBoxOne span {
    width: 100%;
    height: 3px;
    background-color: #abba27;
}

}
