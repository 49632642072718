.GreyApp {
    
}

.App__nav__grey {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(128, 128, 128, 0.612);
    padding: 0 20px;
}

.App__nav__grey__box {  
    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.App__nav__grey__box h1 {
    font-size: 50px;
    font-family: sans-serif;
    font-weight: 600;
    color: #fff;
}

.App__nav__grey__box span {
    width: 100%;
    height: 3px;
    background-color: #abba27;
}